import '../styles/global.scss';
import '../styles/tailwind.css';

import { lazy, useCallback } from 'react';
import React, { FunctionComponent } from 'react';

const WebPreview = lazy(() => import('./WebPreview'));
const Studio = lazy(() => import('./Studio'));
const Web = lazy(() => import('./Web'));
const Api = lazy(() => import('./Api'));
const ErrorPage = lazy(() => import('./404'));

type WrappedAppProps = {
  Component;
  pageProps?;
  router?;
};

const WrappedApp: FunctionComponent<WrappedAppProps> = (props) => {
  // console.log(props.pageProps?.data?.page?.content);

  const findApp = useCallback(
    (preview) => {
      let App = null;

      const hasPageProps = Object.keys(props.pageProps).length;
      const isStudioCall = props?.router?.pathname.includes('/studio/[[...index]]');
      const isApiCall = props?.router?.pathname.includes('/Api ');

      if (isApiCall) {
        App = <Api {...props} />;
      } else if (isStudioCall) {
        App = <Studio {...props} />;
      } else if (hasPageProps) {
        App = preview ? <WebPreview {...props} /> : <Web {...props} />;
      } else {
        // Missing page or error
        App = <ErrorPage />;
      }

      return App;
    },
    [props.pageProps, props?.router]
  );

  return findApp(props.pageProps.preview);
};
export default WrappedApp;
